import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import EditableFormINput from "../../../../common/components/EditableText/EditableFormInput";
import { RedRequiredCircleSVG, YellowAlertSVG } from "../../../../common/components/icons/SVGIcon";
import ToastMessage from "../../../../common/components/Toast";
import { getLocal } from "../../../../helpers/Local";
import { add_timeoff_requests, setGlobalLoading, timeoff_requests } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import TimeOffSidebar from "./TimeOffSidebar";

const options = [
  { value: "vacation", label: "Vacation" },
  { value: "illness", label: "Illness" },
  { value: "bereavement", label: "Bereavement" },
  { value: "education", label: "Education" },
  { value: "other", label: "Other" },
];

const TimeOff = () => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isToastr, setIsToastr] = useState(false);

  const [allHours, setAllHours] = useState(8);
  const [startTime, setStartTime] = useState(moment("09:00", "HH:mm"));
  const [endTime, setEndTime] = useState(moment("17:00", "HH:mm"));
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [allDate, setAllDate] = useState(0);
  const [reason, setReason] = useState(undefined);

  const timeRequests = useSelector((state) => state.common.timeoff_requests);
  const loadingTimeoff = useSelector((state) => state.common.loadingTimeoff);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  const authId = getLocal("cvai-current-user");
  const tableRows = timeRequests ? timeRequests : [];

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (_, index) => {
    setRow(index);
    setIsSidebar(true);
  };

  const checkNumberOf = () => {
    return tableRows[activeIndex] && tableRows[activeIndex].user && tableRows[activeIndex].user.vacation_hours_per_year - timeRemaining();
  };

  const timeMax_start = moment("16:00", "HH:mm").format("HH:mm").toString();
  const timeMin_start = moment("9:00", "HH:mm").format("HH:mm").toString();
  const timeMax_end = moment("17:00", "HH:mm").format("HH:mm").toString();
  const timeMin_end = moment("10:00", "HH:mm").format("HH:mm").toString();

  const changeStartDate = (value) => {
    if (value) {
      const startMoment = moment(value);
      const endMoment = moment(endDate);

      if (endDate && startMoment.isSameOrBefore(endMoment)) {
        setStartDate(value);
        const duration = moment.duration(endMoment.diff(startMoment));
        const days = duration.asDays();
        if (Number(days) > 0) {
          setAllDate(Number(days) * 8);
          setAllHours(Number(days) * 8);
        } else {
          setAllDate(8);
          setAllHours(8);
        }

        setEndTime(null);
      } else {
        alert("Start date must be less than end date");
      }
    }
  };

  const changeEndDate = (value) => {
    if (value) {
      const endMoment = moment(value);
      const startMoment = moment(startDate);

      if (startDate && endMoment.isSameOrAfter(startMoment)) {
        setEndDate(value);
        const duration = moment.duration(endMoment.diff(startMoment));
        const days = duration.asDays();
        if (Number(days) > 0) {
          setAllDate(Number(days) * 8);
          setAllHours(Number(days) * 8);
        } else {
          setAllDate(8);
          setAllHours(8);
        }
        setEndTime(null);
      } else {
        alert("End date must be greater than start date");
      }
    }
  };

  const fetchApi = () => {
    dispatch(timeoff_requests(authId));
  };

  const fetchData = () => {
    fetchApi();
  };

  const clearForm = () => {
    setAllHours(8);
    setStartTime(moment("09:00", "HH:mm"));
    setEndTime(moment("17:00", "HH:mm"));
    setEndDate(moment());
    setStartDate(moment());
    setAllDate(0);
    setReason(undefined);
  };

  const saveAll = async () => {
    const endDay = moment(endDate).format("MM/DD/YYYY");
    const startDay = moment(startDate).format("MM/DD/YYYY");
    const endCheckTime = moment(endTime).format("HH:mm");
    const startCheckTime = moment(startTime).format("HH:mm");

    if (allHours > checkNumberOf()) {
      alert("Hours must be less than remaining hours");
    } else if (!reason) {
      alert("Choose reason for time off");
    } else {
      setLoading(true);
      const checkSuccess = await dispatch(
        add_timeoff_requests({
          user_id: authId,
          start_time: startDay,
          end_time: endDay,
          amount_of_hours: allHours,
          reason: reason,
          approved_user_id: authId,
          is_approved: false,
        })
      );
      if (checkSuccess) {
        fetchApi();
        setLoading(false);
        setIsToastr(true);
        clearForm();
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      }
    }
  };

  const timeRemaining = () => {
    let a = 0;
    if (timeRequests.length > 0) {
      for (const item in timeRequests) {
        if (timeRequests[item].is_approved === 1) {
          a += Number(timeRequests[item].amount_of_hours);
        }
      }
    }
    return a;
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingTimeoff));
  }, [loading, loadingTimeoff]);
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      {isToastr && <ToastMessage type="Schedules" status="success" msg="Saved Successfully!" />}
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div
            className="timeOff__form"
            style={{
              paddingRight: "1rem",
              marginTop: "1rem",
            }}
          >
            <div className="timeOff__form-container">
              <div className="timeOff__form-control">
                <EditableFormINput label={"Start Date"} value={startDate} type="date" handleChange={changeStartDate} />
              </div>

              <div className="timeOff__form-control">
                <EditableFormINput label={"End Date"} value={endDate} type="date" handleChange={changeEndDate} />
              </div>

              <div className="timeOff__form-control">
                <EditableFormINput label={"Hours"} value={allHours} unit="hrs" />
              </div>
              <div className="timeOff__form-control">
                <EditableFormINput
                  label={"Reason"}
                  value={reason}
                  type="check"
                  options={options || []}
                  optionKey="value"
                  valueKey="label"
                  handleChange={setReason}
                />
              </div>
              <div className="timeOff__form-control">
                <EditableFormINput label={"Number of Hours"} value={checkNumberOf()} />
                <div className="timeOff__form-info">
                  <span>
                    <RedRequiredCircleSVG style={{ display: "inline", marginTop: "-2px" }} /> Pending hours remaining are not reflected in the totals
                    above
                  </span>
                </div>
              </div>
            </div>
            <div className="timeOff__form-result">
              <div className="timeOff__form-detail">
                <div className="timeOff__form-detail--value">
                  <b>Total PTO Hours:</b>
                  <div className="w-60"> {currentUserInfo?.vacation_hours_per_year} </div>
                </div>
              </div>
            </div>

            <div
              style={{
                textAlign: "right",
                width: "100%",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
            >
              <button onClick={saveAll} className="common-btn" style={{ border: 0 }}>
                Submit
              </button>
            </div>
          </div>
          <div className="fullContainer pt-2">
            <div className="tableSection">
              <div className="tableContainer">
                <DataTable
                  title="employee_time_off_request"
                  tableWidth={920}
                  labels={["Time Off Start", "Time Off End", "Reason", "Hours", "Approved By"]}
                  widths={["24%", "19%", "19%", "19%", "19%"]}
                  columns={[
                    { sortable: false, key: "start" },
                    { sortable: false, key: "end" },
                    { sortable: false, key: "reason" },
                    { sortable: false, key: "hours" },
                    { sortable: false, key: "approved_by" },
                  ]}
                  sorts={["", "", "", "", ""]}
                  rowData={(tableRows || []).map((row, index) => ({
                    id: row?.id,
                    start: (
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {row?.status === 0 && (
                          <div className="d-flex">
                            <YellowAlertSVG />
                          </div>
                        )}
                        {row?.status === 2 && (
                          <div className="d-flex">
                            <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                          </div>
                        )}
                        {row?.is_approved === 1 && (
                          <div className="d-flex">
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          </div>
                        )}
                        <p style={{ paddingLeft: "0.75rem" }}>{!!row?.start_time && moment(row?.start_time).format("MM/DD/YYYY HH:mm")}</p>
                      </div>
                    ),
                    end: row?.end_time && moment(row?.end_time).format("MM/DD/YYYY HH:mm"),
                    reason: toTitleCase(row?.reason),
                    hours: row?.amount_of_hours,
                    approved_by: row?.approved_user && row?.approved_user?.name,
                  }))}
                  fetchList={fetchData}
                  handleClickRow={handleRow}
                  defaultActive={0}
                  disableDelete={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TimeOffSidebar
        ref={sidebarRef}
        currentUserInfo={currentUserInfo}
        resource={StaticResource}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
    </div>
  );
};

export default TimeOff;
