import { Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import { BASE_API } from "../../../common/constants/constants";
import { SystemHealthTab } from "../constant";
import Health from "./SystemHealth/Health";
import FailedTasks from "./SystemHealth/FailedTasks";
import UnmatchedDicom from "./SystemHealth/UnmatchedDicom";

const draggerProps = {
  name: "file",
  multiple: true,
  action: BASE_API + "process_timesheet",
};

const EmployeeDetail = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="System Health" subStatus={""} isBarcode={false} isFileUpload={false} draggerProps={draggerProps} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {SystemHealthTab.map((tab, index) => (
                <div
                  key={`tabHeaders-${tab.label}-${index}`}
                  className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                  style={{ width: tab.width }}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.label}
                </div>
              ))}
            </Tabs>
          </div>
        </div>
        {activeTab === 0 && <Health title="Done Tasks" />}
        {activeTab === 1 && <FailedTasks title="Failed Tasks" />}
        {activeTab === 2 && <UnmatchedDicom title="Unmatched DICOM" />}
      </div>
    </div>
  );
};

export default EmployeeDetail;
