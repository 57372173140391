export const BASE_SERVER = "";

export const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const BASE_API = process.env.REACT_APP_BASE_API;
export const AUTH_API = process.env.REACT_APP_AUTH_API;
export const BASE_FILE_API = "https://file.faact.com/api/";

export const INVENTORY_API = "https://file.faact.com/api/";
export const DICOM_WEB = "https://dicom.vascularhealthclinics.org/";
export const S3_PATH = "https://cvaipatientfiles.s3.us-east-2.amazonaws.com/";
