import { Icon } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { getPayperiods, getUsers } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import HoursSidebar from "./HoursSidebar";
import HoursFilter from "./HoursFilter";
import { downloadTimeEntryReport } from "src/services/api.services";

const Hours = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [payperiods, setPayperiods] = useState([]);
  const [payperiod, setPayperiod] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const users = useSelector((state) => state.common.users);
  const pay_periods = useSelector((state) => state.common.payperiods);

  const tableRows = users && users.active_users && users.active_users.length > 0 ? users.active_users : [];

  let emptyRows = [];
  const size = useWindowSize(ref);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const setIdLocal = async (id) => {
    await localStorage.setItem("IdEmploy", JSON.stringify(id));
  };

  const handleRow = (index, id) => () => {
    setRow(index);
    if (index !== activeIndex) {
      setIdLocal(id);
      handleSidebar();
    }
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    dispatch(getPayperiods());
  }, []);

  useEffect(() => {
    setPayperiods(pay_periods);
    if (firstLoad && pay_periods?.length > 0) {
      const id = pay_periods[0]?.id;
      setFirstLoad(false);
      setPayperiod(id);
      dispatch(getUsers(id));
    }
  }, [pay_periods]);

  const filterByPayPeriod = (id) => {
    if (!id) return;
    setPayperiod(id);
    dispatch(getUsers(id));
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }
  const sidebarRef = useRef();

  const downloadReport = async () => {
    const data = await downloadTimeEntryReport(payperiod);
    console.log(data);
    const url = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "time_entry_report.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <HoursFilter payPeriods={payperiods} selectedPayperiod={payperiod} handlePayperiod={filterByPayPeriod} downloadReport={downloadReport} />
          <div className="tableHeader" style={{ borderBottom: "2px solid #dfe3e6" }}>
            <div className="tableItem">
              <div className="th" style={{ width: "calc(21% - 3rem)", marginLeft: "3rem" }}>
                <b> {"Employee Name"}</b>
              </div>
              <div className="th" style={{ width: "10%" }}>
                <b>{"Position"} </b>
              </div>
              <div className="th" style={{ width: "10%" }}>
                <b>{"Wage"} </b>
              </div>
              <div className="th" style={{ width: "10%" }}>
                <b>{"Regular Hours"} </b>
              </div>
              <div className="th" style={{ width: "10%" }}>
                <b>{"Travel Hours"} </b>
              </div>
              <div className="th" style={{ width: "10%" }}>
                <b>OT Hours</b>
              </div>
              <div className="th" style={{ width: "10%" }}>
                <b>Primary Benefits</b>
              </div>
              <div className="th" style={{ width: "10%" }}>
                <b>Dependency Benefits</b>
              </div>
              <div className="th" style={{ width: "10%" }}>
                <b>Withholdings</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper css="no-padding x-hidden">
                <div className="tableList">
                  {tableRows.map((row, index) => (
                    <div
                      className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                      key={`resultTable-${title}-${index}`}
                      onClick={handleRow(index, row?.id)}
                    >
                      <div className="td with-icon" style={{ width: "21%" }}>
                        <div className="iconField d-flex">
                          {row?.approved_overtime ? (
                            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          ) : (
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          )}
                        </div>
                        <p>{row?.name}</p>
                      </div>
                      <div className="td" style={{ width: "10%" }}>
                        <p>{row.position}</p>
                      </div>
                      <div className="td" style={{ width: "10%" }}>
                        <p>{row?.meta?.salary}</p>
                      </div>
                      <div className="td" style={{ width: "10%" }}>
                        <p>
                          {row?.total_hours_entries && row?.total_hours_entries?.hours != null
                            ? row?.total_hours_entries?.hours + " hrs"
                            : row?.hours != null
                            ? row?.hours + " hrs"
                            : ""}
                        </p>
                      </div>
                      <div className="td" style={{ width: "10%" }}>
                        <p>
                          {row?.total_hours_entries && row?.total_hours_entries?.travel_hours != null
                            ? row?.total_hours_entries?.travel_hours + " hrs"
                            : row?.travel_hours != null
                            ? row?.travel_hours + " hrs"
                            : ""}
                        </p>
                      </div>
                      <div className="td" style={{ width: "10%" }}>
                        <p>
                          {row?.total_hours_entries && row?.total_hours_entries?.approved_overtime != null
                            ? row?.total_hours_entries?.approved_overtime + " hrs"
                            : row?.approved_overtime != null
                            ? row?.approved_overtime + " hrs"
                            : ""}
                        </p>
                      </div>
                      <div className="td" style={{ width: "10%" }}>
                        <p>${row?.meta?.primary_benefits}</p>
                      </div>
                      <div className="td" style={{ width: "10%" }}>
                        <p>${row?.meta?.dependency_benefits}</p>
                      </div>
                      <div className="td" style={{ width: "10%" }}>
                        <p>${row?.meta?.witholdings || 0}</p>
                      </div>
                    </div>
                  ))}
                  {emptyRows.map((row, index) => (
                    <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                      <div className="td" style={{ width: "21%" }} />
                      <div className="td" style={{ width: "10%" }} />
                      <div className="td" style={{ width: "10%" }} />
                      <div className="td" style={{ width: "10%" }} />
                      <div className="td" style={{ width: "10%" }} />
                      <div className="td" style={{ width: "10%" }} />
                      <div className="td" style={{ width: "10%" }} />
                      <div className="td" style={{ width: "10%" }} />
                      <div className="td" style={{ width: "10%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>

      {tableRows && !!tableRows.length && activeIndex >= 0 && (
        <HoursSidebar
          ref={sidebarRef}
          usersDetail={tableRows && tableRows[activeIndex] ? tableRows[activeIndex] : undefined}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
          payPeriodId={payperiod}
        />
      )}
    </div>
  );
};

export default Hours;
