import { createReducer } from "redux-create-reducer";
import AT from "../actionTypes";

const initialState = {
  adminMeantingfuls: [],
  loading_get_meantingful: false,
  deficiencyList: [],
  totalDeficiency: 0,
  report_link: null,
};

const GET_MEANINGFUL_STATISTICS_STARTED = `${AT.GET_MEANINGFUL_STATISTICS}_STARTED`;
const GET_MEANINGFUL_STATISTICS_SUCCEEDED = `${AT.GET_MEANINGFUL_STATISTICS}_SUCCEEDED`;

const GET_DEFICIENCY_LIST_STARTED = `${AT.GET_DEFICIENCY_LIST}_STARTED`;
const GET_DEFICIENCY_LIST_SUCCEEDED = `${AT.GET_DEFICIENCY_LIST}_SUCCEEDED`;

const EXPORT_REPORT_SUCCEEDED = `${AT.EXPORT_REPORT}_SUCCEEDED`;

export default createReducer(initialState, {
  [GET_MEANINGFUL_STATISTICS_STARTED](state) {
    return Object.assign({}, state, {
      loading_get_meantingful: true,
    });
  },
  [GET_MEANINGFUL_STATISTICS_SUCCEEDED](state, action) {
    const adminMeantingfuls = action?.payload;
    return Object.assign({}, state, {
      adminMeantingfuls,
      loading_get_meantingful: false,
    });
  },
  [GET_DEFICIENCY_LIST_STARTED](state) {
    return Object.assign({}, state, {
      loading_get_meantingful: true,
    });
  },
  [GET_DEFICIENCY_LIST_SUCCEEDED](state, action) {
    const deficiencyList = action?.payload;
    return Object.assign({}, state, {
      deficiencyList: deficiencyList?.data,
      totalDeficiency: deficiencyList?.total,
      loading_get_meantingful: false,
    });
  },
  [EXPORT_REPORT_SUCCEEDED](state, action) {
    const report_link = action?.payload;
    return Object.assign({}, state, {
      report_link,
    });
  },
});
