import { Icon, Select } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteIconButton from "src/common/components/icons/DeleteIconButton";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { getUserActivity, setGlobalLoading } from "../../../../store/actions";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";

const { Option } = Select;

const UserActivity = ({ title }) => {
  const [data, setData] = useState([]);
  const userActivity = useSelector((state) => state.common.userActivity);
  const users = useSelector((state) => state.common.users);
  const userActive = users && users["active_users"] && users["active_users"].length > 0 ? users["active_users"] : [];
  const [activeIndex, setRow] = useState(-1);
  const [idLocal, setIdLocal] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const ref = useRef(null);
  const size = useWindowSize(ref);
  const dispatch = useDispatch();

  let emptyRows = [];

  const getApi = async () => {
    dispatch(setGlobalLoading(true));
    await dispatch(getUserActivity());
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    getApi();
  }, []);

  useEffect(() => {
    setData(userActivity);
  }, [userActivity]);

  const handleRow = (index, id) => () => {
    setRow(index);
    if (index !== activeIndex) {
      setIdLocal(id);
    }
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = data.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > data.length) {
    emptyRows = new Array(rowCount - data.length).fill({});
  }

  const changeEmployee = (value) => {};

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="filterContainer">
            <div className="statusFilterContainer">
              <div className="statusFilter" style={{ minWidth: "260px", marginLeft: "10px" }}>
                <Select
                  placeholder="Filter by Employee Name..."
                  suffixIcon={<ExpandSVG />}
                  allowClear={true}
                  onChange={(value) => {
                    changeEmployee(value);
                  }}
                >
                  {(userActive || []).map((item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="tableHeader" style={{ borderBottom: "2px solid #dfe3e6" }}>
            <div className="tableItem">
              <div className="th" style={{ width: "calc(20% - 3rem)", marginLeft: "3rem" }}>
                <b>{"Date"}</b>
              </div>
              <div className="th" style={{ width: "20%" }}>
                <b>{"Login Time"}</b>
              </div>
              <div className="th" style={{ width: "20%" }}>
                <b>{"Logout Time"}</b>
              </div>
              <div className="th" style={{ width: "20%" }}>
                <b>{"Total Activity Time"}</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper>
                <div className="tableList">
                  {data.map((row, index) => {
                    const loginTime = moment(row?.login_time);
                    const logoutTime = moment(row?.logout_time);
                    const diffInMinutes = logoutTime.diff(loginTime, "minutes");
                    return (
                      <div
                        className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                        key={`resultTable-${title}-${index}`}
                        onClick={handleRow(index, row?.id)}
                      >
                        <div className="td with-icon" style={{ width: "20%" }}>
                          <p>{moment(row?.date).format("MM/DD/YYYY")}</p>
                        </div>
                        <div className="td" style={{ width: "20%" }}>
                          <p>{row?.login_time}</p>
                        </div>
                        <div className="td" style={{ width: "20%" }}>
                          <p>{row?.logout_time}</p>
                        </div>
                        <div className="td" style={{ width: "20%" }}>
                          <p>{diffInMinutes} mins</p>
                        </div>
                      </div>
                    );
                  })}
                  {emptyRows.map((row, index) => (
                    <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                      <div className="td" style={{ width: "20%" }} />
                      <div className="td" style={{ width: "20%" }} />
                      <div className="td" style={{ width: "20%" }} />
                      <div className="td" style={{ width: "20%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserActivity;
