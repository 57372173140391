import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from "../../../../common/components/DataTable/DataTable";
import TopBar from "../../../../common/components/SubTopBar";
import { getLocal, setLocal } from "../../../../helpers/Local";
import {
  angioPatientSnapshotLoadInit,
  choosePatientOnSchedules,
  chooseSelectedPatientResource,
  clinicProviderReviewLoadInit,
  detail_angio_type,
  getConditionDetailData,
  getDeficiencyList,
  getDetailUltrasounds,
  getPatientsByQuery,
  get_cpt_code_review,
  get_patient_notes,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  load_intra_operative,
  load_procedurals,
  patientExaminationLoadInit,
  patient_insurances_id_insurances,
  removeDataPatient,
  remove_patient_on_schedules,
  resetStateResource,
  setActiveReviewChange,
  setDataPatient,
  setGlobalLoading,
  setPatientSnapshotData,
  set_data_template_review,
} from "../../../../store/actions";
import Filter from "./filter";

let timer;

const DeficiencyModule = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [offLoad, setOffLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [forFilter, setForFilter] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const history = useHistory();
  const patients = useSelector((state) => state.dashboard.patients);
  const deficiencyList = useSelector((state) => state.adminMeaningful.deficiencyList);
  const deficiencyListTotal = useSelector((state) => state.adminMeaningful.totalDeficiency);
  const [firstLoad, setFirstLoad] = useState(true);
  const [total, setTotal] = useState(0);
  const loading_get_meantingful = useSelector((state) => state.adminMeaningful.loading_get_meantingful);
  const dispatch = useDispatch();
  const [sortOption, setSortOption] = useState({});

  const initialDeficiencyListTotal = useRef(null);

  useEffect(() => {
    if (deficiencyListTotal && initialDeficiencyListTotal.current === null) {
      initialDeficiencyListTotal.current = deficiencyListTotal;
    }
  }, [deficiencyListTotal]);

  useEffect(() => {
    setLoading(loading_get_meantingful);
  }, [loading_get_meantingful]);

  const sortList = async () => {
    const sortColumn = [];
    for (const sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    setTableLoads([]);
    setLoading(true);
    setPage(1);
    if (!forFilter) {
      await dispatch(
        getDeficiencyList({
          per_page: 100,
          page: 1,
        })
      );
    } else {
      await dispatch(
        getDeficiencyList({
          per_page: 100,
          page: 1,
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    sortList();
  }, [sortOption]);

  useEffect(() => {
    if (search) {
      dispatch(getPatientsByQuery(search));
    }
  }, [search]);

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
  };

  const valueSearch = (value) => {
    clearTimeout(timer);
    setSearch(value);
    setLoading(true);
    timer = setTimeout(() => {
      setTableLoads([]);
      setForFilter(true);
      setPage(1);
      setLoading(false);
      dispatch(
        getDeficiencyList({
          per_page: 100,
          page: 1,
        })
      );
    }, 1000);
  };

  const handleRow = async (row, index) => {
    if (row && (row?.type == "or" || row?.type == "clinic")) {
      await dispatch(setGlobalLoading(true));
      setLocal("current_patient_id", row?.patient_id);
      await dispatch(removeDataPatient());
      await dispatch(resetStateResource());
      await dispatch(remove_patient_on_schedules());
      await dispatch(get_patient_notes(row?.patient_id));
      const result = await dispatch(choosePatientOnSchedules(row?.patient_id));
      await dispatch(setPatientSnapshotData("resource", { ...result }));
      await dispatch(patient_insurances_id_insurances(row?.patient_id));
      await dispatch(setGlobalLoading(false));
      history.push("/clinic-workflow/provider");
      await dispatch(setActiveReviewChange(true));
    }
    if (row && row?.type == "ultrasound") {
      await dispatch(setGlobalLoading(true));
      await dispatch(removeDataPatient());
      await dispatch(resetStateResource());
      await dispatch(remove_patient_on_schedules());
      setLocal("current_patient_id", row?.patient_id);
      setLocal("appointment_ultrasound_id", row?.id);
      setLocal("ultrasound_type_name", row?.ultrasound_type);
      const current_ultrasound_id = getLocal("current_ultrasound_id");
      const result = await dispatch(chooseSelectedPatientResource(row?.patient_id));
      if (result) {
        dispatch(setDataPatient(result));
      }
      await dispatch(patientExaminationLoadInit(row?.id));
      await dispatch(clinicProviderReviewLoadInit(row?.id, 0));
      await dispatch(get_patient_notes(row?.patient_id));
      await dispatch(get_ultrasound_finding_value(row?.id));
      await dispatch(get_ultrasound_type(current_ultrasound_id));
      await dispatch(getDetailUltrasounds(current_ultrasound_id));
      await dispatch(setGlobalLoading(false));
      history.push("/clinic-workflow/ultrasound");
      await dispatch(setActiveReviewChange(true));
    }
    if (row && row?.type == "angio") {
      await dispatch(setGlobalLoading(true));
      await dispatch(resetStateResource());
      await dispatch(remove_patient_on_schedules());
      setLocal("current_patient_id", row?.patient_id);
      await setLocal("current_appointment_id", row?.id);
      await dispatch(get_patient_notes(row?.patient_id));
      const result = await dispatch(choosePatientOnSchedules(row?.patient_id));
      if (result) {
        dispatch(setDataPatient(result));
        dispatch(setPatientSnapshotData("resource", { ...result }));
      }
      await dispatch(setGlobalLoading(false));
      history.push("/obl-workflow/obl");
      await dispatch(setActiveReviewChange(true));
    }
    if (activeIndex !== index) {
      setRow(index);
    }
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    const sortColumn = [];
    for (const sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    if (t >= 1 && Number.isFinite(t) && !loading && !loading_get_meantingful) {
      if (offLoad) {
        setLoading(true);
        const list = await dispatch(
          getDeficiencyList({
            per_page: 100,
            page: page + 1,
          })
        );
        if (list) {
          if (list && list.length > 0) {
            setPage(page + 1);
            setTableLoads([...tableLoad, ...list]);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  const onChangeDate = (value) => {
    setTableLoads([]);
    const date = moment(value).format("MM/DD/YYYY");
    dispatch(
      getDeficiencyList({
        per_page: 100,
        page: 1,
      })
    );
    setStartDate(date);
  };

  useEffect(() => {
    if (!loading_get_meantingful && deficiencyList.length > 0) {
      if (tableLoad.length == 0) {
        setTableLoads([...deficiencyList]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [deficiencyList]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const renderSubStatus = () => {
    return (
      <div className="statusHealth">
        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
        {initialDeficiencyListTotal.current}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
      </div>
    );
  };

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Deficiency Module" subStatus={renderSubStatus()} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <div className="fullContainer pt-2">
              <Filter autoCompleteOptions={patients} onChangeDate={onChangeDate} valueSearch={(value) => valueSearch(value)} />
              <div className="tableSection">
                <div className="tableContainer">
                  <DataTable
                    title="meaningful_use"
                    tableWidth={1120}
                    labels={["Patient Name", "DOB", "DOS", "Appointment Type"]}
                    widths={["25%", "25%", "25%", "25%"]}
                    columns={[
                      { sortable: true, key: "patient_name" },
                      { sortable: false, key: "patient_dob" },
                      { sortable: true, key: "dos" },
                      { sortable: true, key: "appointment_type", hasLink: true },
                    ]}
                    sorts={[
                      sortOption.patient_name,
                      "",
                      sortOption.dos,
                      sortOption.vaccination,
                      "",
                      sortOption.bmi,
                      sortOption.advanced_directive,
                      sortOption.smoking_education,
                      sortOption.hypertension_education,
                      sortOption.alcoholic_education,
                      sortOption.pain_management,
                      sortOption.hypertension,
                      sortOption.medication_reconciliation,
                    ]}
                    rowData={(tableLoad || []).map((row, index) => {
                      const appointment_type_value =
                        row?.type == "angio"
                          ? row?.angio_type?.name
                          : row?.type == "ultrasound"
                          ? row?.ultrasound_type?.name
                          : row?.procedure_type && row?.procedure_type?.value;
                      return {
                        id: row?.id,
                        type: row?.type,
                        patient_id: row?.patient && row?.patient?.id,
                        patient_name: (
                          <div
                            style={{
                              display: "flex",
                              paddingLeft: "0.25rem",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {row?.vaccination === "Yes" &&
                              row?.bmi === "Yes" &&
                              row?.advance_directive === "Yes" &&
                              row?.smoking_education === "Yes" &&
                              row?.hypertension_education === "Yes" &&
                              row?.alcoholic_education === "Yes" &&
                              row?.pain_management === "Yes" &&
                              row?.hypertension === "Yes" &&
                              row?.medication_reconciliation === "Yes" && (
                                <div className="has-tooltip" style={{ paddingBottom: "4px" }}>
                                  {" "}
                                  <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                                </div>
                              )}
                            {(!row?.vaccination !== "Yes" ||
                              !row?.bmi !== "Yes" ||
                              !row?.advance_directive !== "Yes" ||
                              !row?.smoking_education !== "Yes" ||
                              !row?.hypertension_education !== "Yes" ||
                              !row?.alcoholic_education !== "Yes" ||
                              !row?.pain_management !== "Yes" ||
                              !row?.hypertension !== "Yes" ||
                              !row?.medication_reconciliation !== "Yes") && (
                              <div className="has-tooltip" style={{ paddingBottom: "4px" }}>
                                {" "}
                                <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                              </div>
                            )}
                            <p style={{ paddingLeft: "0.625rem" }}>{row?.patient && row?.patient?.name}</p>
                          </div>
                        ),
                        ultrasound_type: (row?.ultrasound_type && row?.ultrasound_type?.name) || "",
                        patient_dob:
                          row?.patient && row?.patient?.date_of_birth && moment(row?.patient && row?.patient?.date_of_birth).format("MM/DD/YYYY"),
                        dos: row?.appointment_date && moment(row?.appointment_date).format("MM/DD/YYYY"),
                        appointment_type: row?.procedure_name,
                        vaccination: row?.vaccination,
                        influenza: row?.influenza,
                        bmi: row?.bmi,
                        advanced_directive: row?.advance_directive,
                        smoking_education: row?.smoking_education,
                        hypertension_education: row?.hypertension_education,
                        alcoholic_education: row?.alcoholic_education,
                        pain_management: row?.pain_management,
                        hypertension: row?.hypertension,
                        medication_reconciliation: row?.medication_reconciliation,
                      };
                    })}
                    handleSort={(value, item) => {
                      handleSortOption(value, item);
                    }}
                    loadMore={loadMore}
                    handleClickRow={handleRow}
                    defaultActive={-1}
                    disableDelete={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeficiencyModule;
