import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { getTimeEntries, setSidebarState } from "../../../../store/actions";
import DashboardSidebar from "./DashboardSidebar";
import { Icon } from "antd";

const Dashboard = ({ loading }) => {
  const [tableRows, setTableRows] = useState([]);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const timeEntries = useSelector((state) => state.common.timeEntries);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      dispatch(setSidebarState(false));
    }
    dispatch(getTimeEntries(currentUserInfo.id, ""));
    dispatch(setSidebarState(true));
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  useEffect(() => {
    if (timeEntries?.length > 0) {
      setTableRows(timeEntries);
    }
  }, [timeEntries]);

  const handleRow = (_, index) => {};

  const loadMore = () => {
    // TODO: API integration with pagination
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_dashboard"
                labels={["Pay Period", "Badge In", "Badge Out", "Regular Hours", "OT Hours"]}
                widths={["24%", "28%", "28%", "10%", "19%"]}
                columns={[
                  { sortable: false, key: "period" },
                  { sortable: false, key: "bagde_in" },
                  { sortable: false, key: "bagde_out" },
                  { sortable: false, key: "regular_hours" },
                  { sortable: false, key: "ot_hours" },
                ]}
                sorts={["", "", "", ""]}
                rowData={(tableRows || []).map((row, index) => {
                  const fromDate = moment(row?.pay_periods?.from_date);
                  const toDate = moment(row?.pay_periods?.to_date);
                  const today = moment();
                  const isTodayInRange = today.isBetween(fromDate, toDate, "day", "[]");
                  return {
                    key: index,
                    id: row?.id,
                    period: (
                      <div
                        className="td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {isTodayInRange && (
                          <div className="iconField d-flex">
                            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          </div>
                        )}
                        {!isTodayInRange && (
                          <div className="iconField d-flex">
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          </div>
                        )}
                        <p style={{ paddingLeft: "0.75rem" }}>
                          {`${moment(row?.pay_periods?.from_date).format("MM/DD/YYYY")} - 
                        ${moment(row?.pay_periods?.to_date).format("MM/DD/YYYY")}`}
                        </p>
                      </div>
                    ),
                    bagde_in: (
                      <>
                        {row?.in_date}
                        {row?.type == "travel" ? (
                          <div
                            className="has-tooltip"
                            style={{ display: "inline-block", marginLeft: "5px", verticalAlign: "3px", float: "left", marginTop: "3px" }}
                          >
                            <Icon type="calendar" theme="twoTone" twoToneColor="#FF832B" />
                            <span
                              className="tooltip tooltip-without-border"
                              role="tooltip"
                              style={{ fontSize: "8px", paddingTop: "8px", textAlign: "left", width: "250px", verticalAlign: "middle" }}
                            >
                              {row?.from_address}
                            </span>
                          </div>
                        ) : null}
                      </>
                    ),
                    bagde_out: (
                      <>
                        {row?.out_date}
                        {row?.type == "travel" ? (
                          <div
                            className="has-tooltip"
                            style={{ display: "inline-block", marginLeft: "5px", verticalAlign: "3px", float: "left", marginTop: "3px" }}
                          >
                            <Icon type="calendar" theme="twoTone" twoToneColor="#FF832B" />
                            <span
                              className="tooltip tooltip-without-border"
                              role="tooltip"
                              style={{ fontSize: "8px", paddingTop: "8px", textAlign: "left", width: "250px", verticalAlign: "middle" }}
                            >
                              {row?.to_address}
                            </span>
                          </div>
                        ) : null}
                      </>
                    ),
                    regular_hours: row?.hours,
                    ot_hours: row?.overtime,
                  };
                })}
                loadMore={loadMore}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {currentUserInfo && currentUserInfo?.name && (
        <DashboardSidebar ref={sidebarRef} currentUserInfo={currentUserInfo} isShow={isSidebar} bagdeTime={timeEntries} />
      )}
    </div>
  );
};

export default Dashboard;
