import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import ToastMessage from "../../common/components/Toast";
import { AUTH_API } from "../../common/constants/constants";
import FogetPasswordForm from "./components/FogetPasswordForm";
import LoginForm from "./components/LoginForm";

import * as API from "../../services/api.services";
import {
  getCurrentPatient,
  getCurrentProvider,
  getCurrentUser,
  getCurrentUserInfo,
  getLocations,
  getProviders,
  setGlobalErrorMessage,
} from "../../store/actions";

import Loading from "../../common/components/Loading";
import { getLocal, removeLocal, setLocal } from "../../helpers/Local";
import "./style.scss";

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const userRole = getLocal("cvai-auth-role");
  const [loading, setLoading] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [isRoleToastr, setIsRoleToastr] = useState(false);
  const [messageError, setMessageError] = useState(null);
  const [isForgetToastr, setIsForgetToastr] = useState(false);
  const [isAuth, setIsAuth] = useState(!!getLocal("cvai-auth-token"));

  useEffect(() => {
    if (isAuth) {
      if (userRole === "patient") {
        props.history.push("/patient-dashboard");
      } else if (userRole === "provider") {
        props.history.push("/referral-dashboard");
      } else {
        props.history.push("/");
      }
    }
  }, [isAuth]);

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular</title>
        <meta name="title" content="FAACT Vascular" />
      </Helmet>
    );
  };

  const handleSubmit = (username, password) => {
    setLoading(true);
    const grant_type = "password";
    const client_id = process.env.REACT_APP_client_id;
    const client_secret = process.env.REACT_APP_client_secret;
    Axios.post(
      AUTH_API,
      {
        grant_type,
        client_id,
        client_secret,
        username,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then(async (result) => {
        console.log(result, "result");
        if (result.data) {
          if (result?.data?.otpSent) {
            props.history.push({
              pathname: "/otp",
              state: { username, password, user_id: result?.data?.user_id },
            });
          }
          setLocal("cvai-auth-token", result.data?.access_token);
          setLocal("cvai-token-type", result.data?.token_type);
          setLocal("cvai-token-expires", result.data?.expires_in);
          setLocal("cvai-auth-role", result.data?.providerInfo);
          if (result?.data?.providerInfo === "user") {
            const checkLoginInfo = await dispatch(getCurrentUser());
            console.log(checkLoginInfo, "checkLoginInfo");
            const checkInfo = await dispatch(getCurrentUserInfo(checkLoginInfo?.id));
            if (checkLoginInfo && checkInfo && checkLoginInfo.status === 1) {
              setIsAuth(true);
              setLoading(false);
              dispatch(getLocations());
              dispatch(getProviders());
              if (checkInfo?.is_provider == 1) {
                setLocal("cvai-current-user-provider-name", checkInfo?.provider?.value || "");
                setLocal("cvai-current-user-provider-id", checkInfo?.provider?.id || "");
              } else {
                removeLocal("cvai-current-user-provider-name");
                removeLocal("cvai-current-user-provider-id");
              }
            } else {
              removeLocal("cvai-auth-token");
              removeLocal("cvai-current-user-provider-name");
              removeLocal("cvai-current-user-provider-id");
              removeLocal("cvai-token-type");
              removeLocal("cvai-token-expires");
              setIsAuth(false);
              setMessageError(null);
              setIsToastr(true);
              setLoading(false);
              setTimeout(() => {
                setIsToastr(false);
              }, 2000);
            }
          } else if (result?.data?.providerInfo === "patient") {
            const checkLoginInfo = await dispatch(getCurrentPatient());
            removeLocal("cvai-current-user-provider-name");
            removeLocal("cvai-current-user-provider-id");
            if (checkLoginInfo && checkLoginInfo.status === 1) {
              props.history.push("/patient-dashboard");
              setIsAuth(true);
              setLoading(false);
            } else {
              removeLocal("cvai-auth-token");
              removeLocal("cvai-token-type");
              removeLocal("cvai-token-expires");
              setIsAuth(false);
              setMessageError(null);
              setIsToastr(true);
              setLoading(false);
              setTimeout(() => {
                setIsToastr(false);
              }, 2000);
            }
          } else if (result?.data?.providerInfo === "provider") {
            const checkLoginInfo = await dispatch(getCurrentProvider());
            removeLocal("cvai-current-user-provider-name");
            removeLocal("cvai-current-user-provider-id");
            if (checkLoginInfo && checkLoginInfo.status === 1) {
              props.history.push("/referral-dashboard");
              setIsAuth(true);
              setLoading(false);
            } else {
              removeLocal("cvai-auth-token");
              removeLocal("cvai-token-type");
              removeLocal("cvai-token-expires");
              setIsAuth(false);
              setMessageError(null);
              setIsToastr(true);
              setLoading(false);
              setTimeout(() => {
                setIsToastr(false);
              }, 2000);
            }
          } else {
            setIsAuth(false);
            setIsRoleToastr(true);
            setLoading(false);
            setTimeout(() => {
              setIsRoleToastr(false);
            }, 2000);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message === "") {
          console.log(error?.response.status, "error?.response.status");
        } else {
          setMessageError(error?.response?.data?.message || error?.response?.data?.error);
        }
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
        setLoading(false);
      });
  };

  const handleForget = async (email) => {
    if (email) {
      setLoading(true);
      const params = {
        email,
      };
      try {
        const response = await API.forgetPassword(params);
        if (response) {
          setLoading(false);
          setIsForgetToastr(true);
          setTimeout(() => {
            setIsForgetToastr(false);
          }, 2000);
          setIsForgetPassword(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  if (isAuth) {
    return null;
  }

  return (
    <div className="loginPage">
      {isToastr && <ToastMessage type="Login" status="failed" msg={messageError || "You Can't Login because account not active!"} />}
      {isRoleToastr && <ToastMessage type="Login" status="failed" msg="Something Went Wrong!" />}
      {isForgetToastr && <ToastMessage type="Forget Password" status="success" msg="Email has been sent successfully!" />}
      {loading && <Loading />}
      {renderMetadata()}
      <div className="loginMainContainer">
        <div className="splashContainer">
          {isForgetPassword ? (
            <FogetPasswordForm
              handleForget={handleForget}
              onClickCancel={() => {
                setIsForgetPassword(false);
              }}
            />
          ) : (
            <LoginForm
              handleSubmit={handleSubmit}
              loginError={loginError}
              onClickForget={() => {
                setIsForgetPassword(true);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
