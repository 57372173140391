import moment from "moment";
import React, { useRef, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { getEvaluations, getEvaluationsDetail, getUsers, getPayperiods } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import EvaluationsFilter from "./EvaluationsFilter";
import EvaluationsSidebar from "./EvaluationsSidebar";

const Evaluations = () => {
  /*
  Use State section
  */
  const pay_periods = useSelector((state) => state.common.payperiods);

  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [date, setDate] = useState(undefined);
  const [employee, setEmployee] = useState(undefined);
  const evaluations = useSelector((state) => state.common.evaluations);
  const [payperiods, setPayperiods] = useState([]);
  const [payPeriodId, setPayPeriodId] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const dispatch = useDispatch();

  const tableRows = evaluations ? evaluations : [];

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, id) => {
    setRow(index);
    dispatch(getEvaluationsDetail(id));
    handleSidebar();
  };

  const changeEmployee = (value) => {
    const employeeValue = value ? value : null;
    setEmployee(employeeValue);
    dispatch(getEvaluations(payPeriodId, employeeValue));
  };

  const filterByPayPeriod = (id) => {
    if (!id) return;
    setPayPeriodId(id);
  };

  useEffect(() => {
    dispatch(getPayperiods());
  }, []);

  useEffect(() => {
    setPayperiods(pay_periods);
    if (firstLoad && pay_periods?.length > 0) {
      const id = pay_periods[0]?.id;
      setFirstLoad(false);
      setPayPeriodId(id);
      dispatch(getEvaluations(id, employee));
      dispatch(getUsers());
    }
  }, [pay_periods]);

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <EvaluationsFilter
            setIsSidebar={setIsSidebar}
            setRow={setRow}
            payPeriods={payperiods}
            selectedPayperiod={payPeriodId}
            handlePayperiod={filterByPayPeriod}
            changeEmployee={(value) => {
              changeEmployee(value);
            }}
          />
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="administrator_employee_evaluation"
                tableWidth={920}
                labels={["Employee Name", "Position", "Points", "Wage"]}
                widths={["31%", "23%", "23%", "23%"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "position" },
                  { sortable: false, key: "points" },
                  { sortable: false, key: "wage" },
                ]}
                sorts={["", "", "", ""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row?.id,
                  name: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ minWidth: "26px" }} />
                      {row?.status === 0 && <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />}
                      {row?.status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                      <p style={{ paddingLeft: "0.75rem" }}>{row.user && row.user?.name}</p>
                    </div>
                  ),
                  position: row.user && row.user.position,
                  points: row.points,
                  wage: row.user.meta && row.user.meta.salary,
                }))}
                handleClickRow={(row, index) => handleRow(index, row?.id)}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {evaluations && (
        <>
          <EvaluationsSidebar
            setIsSidebar={setIsSidebar}
            ref={sidebarRef}
            activeIndex={activeIndex}
            payPeriodId={payPeriodId}
            evaluationsDetail={activeIndex >= 0 ? evaluations[activeIndex] : null}
            resource={StaticResource}
            isShow={isSidebar}
            handleCloseSidebar={handleCloseSidebar}
          />
        </>
      )}
    </div>
  );
};

export default Evaluations;
