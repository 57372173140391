import React from "react";
import moment from "moment";
import { Button, Select } from "antd";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";

const { Option } = Select;

const HoursFilter = ({ payPeriods, selectedPayperiod, handlePayperiod, downloadReport }) => {
  return (
    <div className="filterContainer">
      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: "224px", marginLeft: "0" }}>
          <Select
            style={{
              width: "100%",
            }}
            placeholder="Filter by Pay Period"
            suffixIcon={<ExpandSVG />}
            value={selectedPayperiod}
            onChange={(payperiod) => handlePayperiod(payperiod)}
          >
            {payPeriods?.map((payperiod, index) => (
              <Option key={`payperiod-${index}`} value={payperiod?.id}>
                {`${moment(payperiod.from_date).format("MM/DD/YYYY")} - ${moment(payperiod.to_date).format("MM/DD/YYYY")}`}
              </Option>
            ))}
          </Select>
        </div>
        <div className="actionContent">
          <button onClick={downloadReport} className="common-btn blue-btn" style={{ marginTop: "11px", marginLeft: "10px" }}>
            Export CSV
          </button>
        </div>
      </div>
    </div>
  );
};

export default HoursFilter;
