import { Drawer, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginFormInput from "../../../../common/components/EditableText/LoginFormInput";
import { ExpandSVG, SummaryEmptySVG } from "../../../../common/components/icons/SVGIcon";
import Ratings from "../../../../common/components/Ratings";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { RatingOptions } from "../../constant";
import { getEvaluations, setGlobalLoading, setGlobalToastr, updateEvaluationDetail } from "src/store/actions";
import { save_new_evaluations, finalizeEvaluation } from "src/services/api.services";

const { Option } = Select;

const EvaluationsSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, evaluationsDetail, setIsSidebar, payPeriodId }, ref) => {
  const [ratingsDetail, setRatingsDetail] = useState({
    job_score: -1,
    work_score: -1,
    punctuality_score: -1,
    initiative_score: -1,
    communication_score: -1,
    dependability_score: -1,
  });
  const [comment, setComment] = useState("");
  const [goal, setGoal] = useState("");
  const [userId, setUserId] = useState(null);
  const [isAddNew, setIsAddNew] = useState(true);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const userActive = users && users["active_users"] && users["active_users"].length > 0 ? users["active_users"] : [];

  const saveDefault = (data) => {
    if (data) {
      setRatingsDetail({
        job_score: data?.job_score || -1,
        work_score: data?.work_score || -1,
        punctuality_score: data?.punctuality_score || -1,
        initiative_score: data?.initiative_score || -1,
        communication_score: data?.communication_score || -1,
        dependability_score: data?.dependability_score || -1,
      });
      setGoal(data?.goals);
      setComment(data?.additional_comments);
      setUserId(data?.user_id);
    } else {
      setRatingsDetail({
        job_score: -1,
        work_score: -1,
        punctuality_score: -1,
        initiative_score: -1,
        communication_score: -1,
        dependability_score: -1,
      });
      setGoal("");
      setComment("");
      setUserId(null);
    }
  };

  useEffect(() => {
    if (evaluationsDetail) {
      setIsAddNew(false);
      saveDefault(evaluationsDetail);
    }

    return () => {
      setIsAddNew(true);
      saveDefault(null);
    };
  }, [evaluationsDetail]);

  const handleRatings = (type) => (value) => {
    const newDetail = { ...ratingsDetail, [type]: value };
    setRatingsDetail(newDetail);
    handleUpdateEvaluation();
  };

  const handleUpdateEvaluation = () => {
    const params = {
      ...ratingsDetail,
      user_id: userId,
      period_id: payPeriodId,
      goals: goal,
      additional_comments: comment,
    };
    if (!params?.period_id || !params?.user_id) {
      dispatch(
        setGlobalToastr({
          header: "New Evaluation Failed",
          message: "Please check your User and Period!",
          type: "failed",
        })
      );
      return;
    }
    dispatch(setGlobalLoading(true));
    dispatch(updateEvaluationDetail(evaluationsDetail?.id, params));
    dispatch(setGlobalLoading(false));
    dispatch(setGlobalToastr({ header: "Update Evaluation", message: "Update Evaluation Successfully!", type: "success" }));
  };

  const handleChangeAdd = (isActiveSave) => {
    setIsAddNew(isActiveSave);
    if (isActiveSave) {
      saveDefault(null);
    } else {
      saveDefault(evaluationsDetail);
    }
  };
  const handleSaveNew = async () => {
    dispatch(setGlobalLoading(true));

    const params = {
      ...ratingsDetail,
      user_id: userId,
      period_id: payPeriodId,
      goals: goal,
      additional_comments: comment,
    };
    if (!params?.period_id || !params?.user_id) {
      dispatch(
        setGlobalToastr({
          header: "New Evaluation Failed",
          message: "Please check your User and Period!",
          type: "failed",
        })
      );
      return;
    }
    try {
      await save_new_evaluations(params);
      await dispatch(getEvaluations(payPeriodId, userId));
      setIsSidebar && setIsSidebar(false);
      dispatch(
        setGlobalToastr({
          header: "New Evaluation",
          message: "New Evaluation Successfully!",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        setGlobalToastr({
          header: "New Evaluation Failed",
          message: error?.message,
          type: "failed",
        })
      );
    }
    dispatch(setGlobalLoading(false));
  };

  const getOverallRating = () => {
    let sum = 0;
    for (const opt of RatingOptions) {
      sum += ratingsDetail[opt.key] === -1 ? 0 : ratingsDetail[opt.key];
    }

    return Math.round(sum);
  };

  const handleFinalize = () => {
    dispatch(setGlobalLoading(true));
    finalizeEvaluation(evaluationsDetail?.id);
    dispatch(getEvaluations(payPeriodId, userId));
    setIsSidebar && setIsSidebar(false);
    dispatch(setGlobalLoading(false));
  };

  // if (isShow && !evaluationsDetail && !isAddNew) {
  //   return (
  //     <Drawer
  //       title="NEW EVALUATION"
  //       placement="right"
  //       closable
  //       className={sideNavStatus ? "wide" : ""}
  //       onClose={handleCloseSidebar}
  //       visible={isShow}
  //       destroyOnClose
  //       key="right"
  //     >
  //       <div ref={ref} className="resourceContainer new">
  //         <div
  //           className="emptyContentIcon"
  //           style={{
  //             width: "100%",
  //             height: "calc(100% - 120px)",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <SummaryEmptySVG />
  //         </div>
  //         <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem" }}>
  //           <button
  //             onClick={() => {
  //               setIsAddNew(true);
  //             }}
  //           >
  //             Create Evolution
  //           </button>
  //         </div>
  //       </div>
  //     </Drawer>
  //   );
  // }

  const renderTitle = () => {
    return <span className="text-uppercase">{evaluationsDetail && evaluationsDetail?.user && evaluationsDetail?.user?.name}</span>;
  };

  return (
    <Drawer
      title={evaluationsDetail?.user?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "1rem",
            }}
          >
            <div className="resourceInfo d-flex between">
              <b>User: </b>
              <Select
                placeholder="User"
                suffixIcon={<ExpandSVG />}
                allowClear={true}
                value={userId}
                onChange={(value) => {
                  setUserId(value);
                }}
              >
                {(userActive || []).map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </div>
            <hr />
            <div className="resourceInfo">
              {RatingOptions.map((opt, index) => (
                <Ratings
                  key={`ratings-${opt.key}`}
                  isIcon={true}
                  label={opt.label}
                  defaultOption={ratingsDetail[opt.key]}
                  handleOption={handleRatings(opt.key)}
                />
              ))}
            </div>
            <hr />
            <div className="resourceInfo d-flex between">
              <b>Overall Rating: </b>
              <b style={{ paddingRight: "0.5rem" }}>{getOverallRating()}</b>
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "1rem", width: "100%" }}>
              <LoginFormInput
                label={"Additional Comments"}
                type={"area"}
                handleChange={(value) => {
                  // Todo: Integrate API or edit function
                  setComment(value);
                  handleUpdateEvaluation();
                }}
                fullWidth={true}
                value={comment}
                minRows={3}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex" style={{ width: "100%" }}>
              <LoginFormInput
                label={"Goals"}
                type={"area"}
                handleChange={(value) => {
                  // Todo: Integrate API or edit function
                  setGoal(value);
                  handleUpdateEvaluation();
                }}
                fullWidth={true}
                value={goal}
                minRows={3}
              />
            </div>
            <hr />
          </div>

          {isAddNew ? (
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                paddingBottom: "2rem",
              }}
            >
              <button
                onClick={() => {
                  handleSaveNew();
                }}
                style={{ marginRight: "10px" }}
              >
                Save
              </button>

              <button
                onClick={() => {
                  handleChangeAdd(false);
                }}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                paddingBottom: "2rem",
              }}
            >
              <button
                onClick={() => {
                  handleChangeAdd(true);
                }}
                style={{ marginRight: "10px" }}
              >
                Add New
              </button>

              <button onClick={handleFinalize}>Finalize +</button>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default EvaluationsSidebar;
