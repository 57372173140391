import { Drawer } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { getUsers, saveEmployeeInfo } from "../../../../store/actions";
import ExtendDataTable from "src/common/components/SubDataTable/ExtendDataTable";
import { getTimeEntries } from "../../../../store/actions";

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const HoursSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, usersDetail, payPeriodId }, ref) => {
  const [state, setState] = useState({});

  const [isToastr, setIsToastr] = useState(false);
  const [reset, setReset] = useState(true);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const [isReset, setIsReset] = useState(false);
  const timeEntries = useSelector((state) => state.common.timeEntries);
  const [tableRows, setTableRows] = useState([]);

  const [tableColumnWidths, setTableColumnWidths] = useState(["25%", "25%", "25%", "25%"]);

  const dispatch = useDispatch();
  const fetchState = () => {
    setState({
      id: usersDetail?.id,
      position: usersDetail.position,
      hours: usersDetail.meta && usersDetail.meta.hours,
      overtime: usersDetail.meta && usersDetail.meta.overtime,
      witholdings: usersDetail.meta && usersDetail.meta.witholdings,
      bonus: usersDetail.meta && usersDetail.meta.bonus,
      exemptions: usersDetail.meta && usersDetail.meta.exemption,
      ovhendo: usersDetail.meta && usersDetail.meta.ovh_endo,
      ovhnonendo: usersDetail.meta && usersDetail.meta.obh_nonendo,
      salary: usersDetail.meta && usersDetail.meta.salary,
      payforpay: usersDetail.meta && usersDetail.meta.pay_for_pay_period,
      ops_provider_id: usersDetail.ops_provider_id,
      shiftstart: usersDetail.meta && moment(usersDetail.meta.shift_start_time).format("kk:mm"),
      shiftend: usersDetail.meta && moment(usersDetail.meta.shift_end_time).format("kk:mm"),
      start: usersDetail.meta && moment(usersDetail.meta.hire_date).format("MM/DD/YYYY"),
      pto: usersDetail.vacation_hours_per_year,
      name: usersDetail.name,
      nonendo_cm: usersDetail.nonendo_cm,
      address: usersDetail.address,
      address_2: usersDetail.address_2,
      city: usersDetail.city,
      state: usersDetail.state,
      ssn: usersDetail.ssn,
      phone: usersDetail.phone,
      email: usersDetail.email,
      zipcode: usersDetail.zipcode,
      primary_benefits: usersDetail.meta?.primary_benefits,
      dependency_benefits: usersDetail.meta?.dependency_benefits,
      lunch_time: usersDetail.meta?.lunch_time,
    });
  };

  useEffect(() => {
    dispatch(getTimeEntries(usersDetail?.id, payPeriodId));
  }, []);

  useEffect(() => {
    if (timeEntries?.length > 0) {
      setTableRows(timeEntries);
    }
  }, [timeEntries]);

  const saveAll = async (value) => {
    if (Object.keys(usersDetail).length > 0) {
      const saveSuccess = await dispatch(
        saveEmployeeInfo(usersDetail.id, {
          position: value.position,
          hours: value.hours,
          overtime: value.overtime,
          witholdings: value.witholdings,
          bonus: value.bonus,
          exemption: value.exemptions,
          ovh_endo: value.ovhendo,
          obh_nonendo: value.ovhnonendo,
          salary: value.salary,
          pay_for_pay_period: value.payforpay,
          vacation_hours: value.pto,
          shift_start_time: value.shiftstart,
          shift_end_time: value.shiftend,
          start_date: value.start,
          ops_provider_id: value.ops_provider_id,
          pay_period_id: payPeriodId,
          nonendo_cm: value.nonendo_cm,
          name: value.name,
          address: value.address,
          address_2: value.address_2,
          city: value.city,
          state: value.state,
          ssn: value.ssn,
          phone: value.phone,
          email: value.email,
          zipcode: value.zipcode,
          primary_benefits: value.primary_benefits,
          dependency_benefits: value.dependency_benefits,
          lunch_time: value.lunch_time,
        })
      );
      const getList = await dispatch(getUsers(payPeriodId));
      if (saveSuccess && getList) {
        setIsToastr(true);
        setIsReset(false);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      }
    }
  };

  const handleChange = (type, value) => {
    const newState = {
      ...state,
      [type]: value,
    };
    setState(newState);
    isReset && changeHandler(newState);
  };

  const changeHandler = useCallback(
    debounce((value) => saveAll(value), 400),
    []
  );

  useEffect(() => {
    if (usersDetail && usersDetail?.id !== state?.id) {
      fetchState();
      setReset(true);
      setIsReset(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
  }, [usersDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  return (
    <Drawer
      title={usersDetail?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="Hours" status="success" msg="Saved Successfully!" />}
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              marginTop: "1.5rem",
            }}
          >
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Name"}
                value={state?.name}
                handleChange={(value) => {
                  handleChange("name", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Address"}
                value={state?.address}
                handleChange={(value) => {
                  handleChange("address", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Apt"}
                value={state?.address_2}
                handleChange={(value) => {
                  handleChange("address_2", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"City"}
                value={state?.city}
                handleChange={(value) => {
                  handleChange("city", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"State"}
                value={state?.state}
                handleChange={(value) => {
                  handleChange("state", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"SSN"}
                value={state?.ssn}
                mask={"ssn"}
                handleChange={(value) => {
                  handleChange("ssn", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Phone"}
                value={state?.phone}
                mask={"phone"}
                handleChange={(value) => {
                  handleChange("phone", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"E-Mail"}
                value={state.email}
                handleChange={(value) => {
                  handleChange("email", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Position: Regular"}
                value={state.position}
                handleChange={(value) => {
                  handleChange("position", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Hours"}
                value={state.hours}
                handleChange={(value) => {
                  handleChange("hours", value);
                }}
                unit={"hrs"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Overtime"}
                value={state.overtime}
                handleChange={(value) => {
                  handleChange("overtime", value);
                }}
                unit={"hrs"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Primary Benefits"}
                value={state.primary_benefits}
                handleChange={(value) => {
                  handleChange("primary_benefits", value);
                }}
                prefix={"$"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Dependency Benefits"}
                value={state.dependency_benefits}
                handleChange={(value) => {
                  handleChange("dependency_benefits", value);
                }}
                prefix={"$"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Witholdings"}
                prefix={"$"}
                value={state.witholdings}
                handleChange={(value) => {
                  handleChange("witholdings", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Exemptions"}
                value={state.exemptions}
                handleChange={(value) => {
                  handleChange("exemptions", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Salary/Wage"}
                prefix={"$"}
                value={state.salary}
                handleChange={(value) => {
                  handleChange("salary", value);
                }}
              />
            </div>

            <div className="resourceInfo d-flex">
              <EditableMaterialText
                label={"Start Date"}
                value={state.start}
                type={"date"}
                reset={reset}
                handleChange={(value) => {
                  handleChange("start", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"PTO Hours"}
                value={state.pto}
                handleChange={(value) => {
                  handleChange("pto", value);
                }}
                unit={"hrs"}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex">
              <button onClick="approveHours">Approve OT Hours</button>
            </div>
            <div className="resourceInfo d-flex">
              <ExtendDataTable
                isActiveBorder={true}
                isFixed={true}
                title={
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        ...titleStyle,
                        width: tableColumnWidths[0],
                        textAlign: "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingRight: "4px",
                        borderLeft: 0,
                      }}
                    >
                      Badge In
                    </div>
                    <div
                      style={{
                        ...titleStyle,
                        width: tableColumnWidths[0],
                        textAlign: "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingRight: "4px",
                        borderLeft: 0,
                      }}
                    >
                      Badge Out
                    </div>
                    <div
                      style={{
                        ...titleStyle,
                        width: tableColumnWidths[0],
                        textAlign: "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingRight: "4px",
                        borderLeft: 0,
                      }}
                    >
                      IP In
                    </div>
                    <div
                      style={{
                        ...titleStyle,
                        width: tableColumnWidths[0],
                        textAlign: "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingRight: "4px",
                        borderLeft: 0,
                      }}
                    >
                      IP Out
                    </div>
                  </div>
                }
                data={(tableRows || []).map((row, index) => {
                  return {
                    key: index,
                    id: row?.id,
                    bagde_in: row?.in_date,
                    bagde_out: row?.out_date,
                    ip_in: row?.user_ip_in,
                    ip_out: row?.user_ip_out,
                  };
                })}
                options={["bagde_in", "bagde_out", "ip_in", "ip_out"]}
              ></ExtendDataTable>
            </div>
          </div>
          <div className="actionContent" style={{ paddingBottom: "2rem" }} />
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default HoursSidebar;
