import { Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import { BASE_API } from "../../../common/constants/constants";
import { EmployeeHeaders } from "../constant";
import CME from "./EmployeeDetails/CME";
import Documents from "./EmployeeDetails/document";
import Evaluations from "./EmployeeDetails/Evaluations";
import Hours from "./EmployeeDetails/Hours";
import ManualPolicies from "./EmployeeDetails/ManualPolicies";
import Schedules from "./EmployeeDetails/Schedules";
import VacationRequest from "./EmployeeDetails/VacationRequest";
import Variances from "./EmployeeDetails/Variances";
import Tasks from "./EmployeeDetails/Tasks";
import UserActivity from "./EmployeeDetails/UserActivity";
const draggerProps = {
  name: "file",
  multiple: true,
  action: BASE_API + "process_timesheet",
};

const EmployeeDetail = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Employee Details" subStatus={""} isBarcode={false} isFileUpload={false} draggerProps={draggerProps} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {EmployeeHeaders.map((tab, index) => (
                <div
                  key={`tabHeaders-${tab.label}-${index}`}
                  className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                  style={{ width: tab.width }}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.label}
                </div>
              ))}
            </Tabs>
          </div>
        </div>
        {activeTab === 0 && <Hours />}
        {activeTab === 1 && <VacationRequest />}
        {activeTab === 2 && <Variances />}
        {activeTab === 3 && <Evaluations />}
        {activeTab === 4 && <ManualPolicies />}
        {activeTab === 5 && <Schedules />}
        {activeTab === 6 && <Tasks />}
        {activeTab === 7 && <UserActivity />}
      </div>
    </div>
  );
};

export default EmployeeDetail;
