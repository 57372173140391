import { Icon } from "antd";
import React from "react";
import { YellowAlertSVG } from "../../common/components/icons/SVGIcon";
import DocumentAssignment from "./components/DocumentAssignment";
import EmployeeModule from "./components/EmployeeModule";
import DeficiencyModule from "./components/MeaningfulUse";

export const Children = {
  employee: EmployeeModule,
  "document-assignment": DocumentAssignment,
  deficiency: DeficiencyModule,
};

export const TabHeaders = [
  { label: "Dashboard", width: "auto" },
  { label: "Time-Off Request", width: "auto" },
  { label: "Variance Reporting", width: "auto" },
  { label: "Evaluations", width: "auto" },
  { label: "Manual & Policies", width: "auto" },
];
export const StaticResource = {
  first_name: "James",
  last_name: "Fisher",
  date_of_birth: "06/09/1973",
  phone: "(989) 897-8797",
  secondary_phone: "(989) 897-8797",
  email: "haqqani@hotmail.com",
  ssn: "890-09-8977",
  address: "1109 W St Andrews Rd",
  apt_number: "",
  suite: "",
  bldg: "",
  state: "Michigan",
  city: "Midland",
  zipcode: "48642",
  clinic_date: "07/04/2019",
  result: "",
  icon: <YellowAlertSVG />,
};

export const DashboardRows = [
  { icon: <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" /> },
  { icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" /> },
];
